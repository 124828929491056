<!--
All three of these divs may be shown at the same time or independently.

For example:

1. Just a Color with Opacity
2. Just an Image with Opacity
3. Just a Video with Opacity
4. Just an Audio
3. Any combination of the above

However the layers are always in that order.  For example supplying a Color and a Video, the Color will be below the Video.

---------------------------------------
APP-581 - Move to Fixed Mode by default.

The class "image-fixed" is used by default in all cases. The only time that isn't true is when
the application is running inside an auto widget. So all we need to know is if the widget is
"auto" or not which drives the conditional.
---------------------------------------
-->

<!-- Color -->
@if (config?.color) {
  <div class="color" [style.opacity]="config.colorOpacity" [style.background-color]="config.color"></div>
}

<!-- Image -->
@if (config?.image) {
  <div
    [class.image]="(appRepository.shouldApplyWidgetAutoLayout$ | async) === true"
    [class.image-fixed]="(appRepository.shouldApplyWidgetAutoLayout$ | async) === false"
    [style.opacity]="config.imageOpacity"
    [style.background-image]="this.domSanitizer.bypassSecurityTrustUrl(config.image)"
  ></div>
}

<!-- Video -->
@if (config?.video?.length > 0 && (videoError$ | async) === false) {
  <div class="video-container" [style.opacity]="config.videoOpacity">
    <video #video id="video" (error)="videoError()" (loadedmetadata)="videoLoadedMetaData()">
      @for (source of config.video; track source.src) {
        <source [src]="source.src" [type]="source.type" />
      }
    </video>
  </div>
}
