<div
  class="mat-app-background mat-typography direct"
  [class.widget-auto]="appRepository.shouldApplyWidgetAutoLayout$ | async"
  [class.widget-fixed]="appRepository.shouldApplyWidgetFixedLayout$ | async"
  [ngClass]="footerRepository.appLayoutClass$ | async"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutAlign.gt-xs="start stretch"
>
  <app-shell-background></app-shell-background>
  <app-shell-outlet-container
    [fxLayout]="(appRepository.shellLayout$ | async) || 'row'"
    [fxLayoutAlign]="(appRepository.shellLayoutAlign$ | async) || 'center center'"
  ></app-shell-outlet-container>
  <app-shell-loader></app-shell-loader>
  <app-shell-fg-effects-container></app-shell-fg-effects-container>
  @if (footerRepository.showFooter$ | async) {
    <app-footer fxLayout="column" fxLayoutAlign="end stretch"></app-footer>
  }
  <!-- Cookie Banner -->
  @if (cookiesRepository.consent$ | async; as cookiesConsent) {
    <app-cookie-consent [config]="cookiesConsent"> </app-cookie-consent>
  }
  <!-- Dev Panel Dev -->
  @if (appRepository.showDevPanel$ | async) {
    <app-dev-panel></app-dev-panel>
  }
</div>
