import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFooterBranding } from 'src/app/api/modules/core/components/static/IFooterBranding';
import { ImageComponent } from '../../../dynamic/components/image/image.component';
import { AbstractLinkComponent } from '../../abstract/abstract-link/abstract-link.component';
import { FooterBrandingRepository } from './footer-branding.repository';

@Component({
  selector: 'app-footer-branding',
  templateUrl: './footer-branding.component.html',
  styleUrls: ['./footer-branding.component.scss'],
  providers: [FooterBrandingRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AbstractLinkComponent, ImageComponent, AsyncPipe],
})
/**
 * The Footer Branding Component is used within a Footer.
 * It usually says "DigitaService by Drimlike" although the content is open to change
 */
export class FooterBrandingComponent {
  /**
   * The Configuration required for this component to function
   */
  private _config?: IFooterBranding;
  @Input()
  set config(configuration: IFooterBranding) {
    this._config = configuration;
    this.footerBrandingRepository.applyConfiguration(configuration);
  }
  get config(): IFooterBranding {
    return this._config;
  }

  constructor(public readonly footerBrandingRepository: FooterBrandingRepository) {}
}
