@if (config) {
  <div class="container" fxLayout="row" fxLayoutAlign="center center">
    @if (config.message; as message) {
      <div class="message-container" fxFlex="grow">
        <span>
          {{ message }}
          @if (config.learnMore; as learnMore) {
            <app-abstract-link [config]="learnMore"
              ><span class="link-text">{{ learnMore.title }}</span></app-abstract-link
            >
          }
        </span>
      </div>
    }
    <!-- Button Container depending if any buttons are available -->
    @if (config.accept || config.decline) {
      <app-button-container-template class="button-container" fxLayout="column" fxLayoutAlign="center center" fxFlex="initial">
        <!-- Optional Accept and Decline buttons -->
        @if (config.accept; as accept) {
          <app-button [config]="accept" (buttonClick)="acceptConsent()"></app-button>
        }
        <!--@if (config.accept && config.decline) {
          <span class="separator"></span>
      } -->
        @if (config.decline; as decline) {
          <app-button [config]="decline" (buttonClick)="rejectConsent()"></app-button>
        }
      </app-button-container-template>
    }
  </div>
}
