import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { ICookieConsent } from 'src/app/api/modules/core/components/static/cookies/ICookieConsent';
import { ButtonComponent } from '../../../dynamic/components/button/button.component';
import { ButtonContainerTemplateComponent } from '../../../dynamic/containers/button-container-template/button-container-template.component';
import { CookiesService } from '../../../services/cookies/cookies.service';
import { AbstractLinkComponent } from '../abstract-link/abstract-link.component';

/**
 * The Cookie Consent Component is used to offer the user cookie controls.
 */
@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AbstractLinkComponent, ButtonContainerTemplateComponent, FlexModule, ButtonComponent],
})
export class CookieConsentComponent {
  /**
   * The configuration required for this object to function
   */
  @Input() config?: ICookieConsent;

  /**
   * Constructor
   */
  constructor(private readonly cookiesService: CookiesService) {}

  /**
   * Applies if there is an `accept` button and the user clicks it.
   */
  acceptConsent() {
    this.cookiesService.applyConsent();
  }

  /**
   * Applies if there is a `decline` button and the user clicks it.
   */
  rejectConsent() {
    this.cookiesService.rejectConsent();
  }
}
