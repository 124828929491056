import {
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  BrowserClient,
  browserProfilingIntegration,
  browserTracingIntegration,
  contextLinesIntegration,
  dedupeIntegration,
  defaultStackParser,
  extraErrorDataIntegration,
  functionToStringIntegration,
  globalHandlersIntegration,
  httpClientIntegration,
  httpContextIntegration,
  inboundFiltersIntegration,
  linkedErrorsIntegration,
  makeFetchTransport,
  moduleMetadataIntegration,
  rewriteFramesIntegration,
  sessionTimingIntegration,
} from '@sentry/angular';
import { SentryDenyUrls, SentryDSN, SentryReleaseName } from './SentryClientShared';

/**
 * @returns A new Sentry client for the production environment.
 */
export const SentryClientProduction = () => {
  const client = new BrowserClient({
    dsn: SentryDSN,
    debug: false,
    release: SentryReleaseName(),
    environment: 'production',
    sampleRate: 0.5,
    tracesSampleRate: 0.1,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    ignoreErrors: [],
    ignoreTransactions: [],
    denyUrls: SentryDenyUrls,
    autoSessionTracking: true,
    maxValueLength: 250,
    normalizeDepth: 3,
    normalizeMaxBreadth: 1000,
    sendClientReports: true,
    integrations: [
      breadcrumbsIntegration(),
      browserApiErrorsIntegration(),
      dedupeIntegration(),
      functionToStringIntegration(),
      globalHandlersIntegration(),
      httpContextIntegration(),
      inboundFiltersIntegration(),
      linkedErrorsIntegration(),
      browserProfilingIntegration(),
      browserTracingIntegration(),
      contextLinesIntegration(),
      extraErrorDataIntegration(),
      httpClientIntegration(),
      moduleMetadataIntegration(),
      rewriteFramesIntegration(),
      sessionTimingIntegration(),
    ],
    enableTracing: true,
    stackParser: defaultStackParser,
    transport: makeFetchTransport,
  });
  return client;
};
