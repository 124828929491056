import '@digitaservice/utils';

import { LoggerLevel, LoggerModule } from '@angular-ru/cdk/logger';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, RouteReuseStrategy, withRouterConfig } from '@angular/router';
import { DigitaServiceError } from '@digitaservice/utils';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { enableElfProdMode } from '@ngneat/elf';
import { FormlyModule } from '@ngx-formly/core';
import { BrowserClient, getCurrentScope, TraceService } from '@sentry/angular';
import { DEFAULT_MAT_FORM_FIELD_OPTIONS, DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS, DefaultDialogSettings } from './app/app-constants';
import { AppInitializeService } from './app/app-initialize.service';
import { AppRouteReuseStrategy } from './app/app-route-reuse-strategy';
import { AppRoutes } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { AppErrorHandler } from './app/app.error-handler';
import { environment } from './environments/environment';
import { SentryClientDevelopment } from './sentry/SentryClientDevelopment';
import { SentryClientLocal } from './sentry/SentryClientLocal';
import { SentryClientProduction } from './sentry/SentryClientProduction';

//////////////////////////////////////////////////////////////////////////////
// Native Angular Production Mode
//////////////////////////////////////////////////////////////////////////////

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
} else {
  console.log('----------------------------------');
  console.log('    😎 DigitaService Version:', environment.environmentVersions.version, 'Name:', environment.environmentName);
  console.log('----------------------------------');
}

//////////////////////////////////////////////////////////////////////////////
// Sentry Client
//
// https://docs.sentry.io/platforms/javascript/guides/angular/
//////////////////////////////////////////////////////////////////////////////

let client: BrowserClient;

switch (environment.environmentName) {
  default:
    client = SentryClientLocal();
    break;
  case 'production':
    client = SentryClientProduction();
    break;
  case 'development':
    client = SentryClientDevelopment();
    break;
}

getCurrentScope().setClient(client);
client.init();

//////////////////////////////////////////////////////////////////////////////
// Bootstrap Application
//////////////////////////////////////////////////////////////////////////////

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      LoggerModule.forRoot({
        minLevel: environment.production ? LoggerLevel.OFF : LoggerLevel.ALL,
      }),
      BrowserModule,
      FontAwesomeModule,
      FormlyModule.forRoot(),
    ),
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    /**
     * Default Settings for Dialogs App Wide
     */
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: new DefaultDialogSettings() },
    /**
     * Default Settings for Form Fields App Wide
     */
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: DEFAULT_MAT_FORM_FIELD_OPTIONS,
    },
    /**
     * Default Settings for Mat Progress Spinners App Wide
     */
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: DEFAULT_MAT_PROGRESS_SPINNER_OPTIONS,
    },
    AppInitializeService,
    provideRouter(
      AppRoutes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch(() => {
  throw new DigitaServiceError(`Bootstrap Error: Cannot start the application`);
});
