import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AppRepository } from '../../../services/application/application.repository';

@Component({
  selector: 'app-shell-loader',
  templateUrl: './shell-loader.component.html',
  styleUrls: ['./shell-loader.component.scss'],
  standalone: true,
  imports: [MatProgressBar, AsyncPipe],
})
export class ShellLoaderComponent {
  /**
   * The Shell Loader Component is the main loading indicator
   */
  constructor(public readonly appRepository: AppRepository) {}
}
