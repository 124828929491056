import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FabIconDefinitions, FabIcons, FabList } from './awesome/Fab';
import { FarIconDefinitions, FarIcons, FarList } from './awesome/Far';
import { FasIconDefinitions, FasIcons, FasList } from './awesome/Fas';

export type SupportedIconNames = FarIcons | FasIcons | FabIcons;

const SupportedIconNamesStrings = {
  ...FarList,
  ...FasList,
  ...FabList,
};

/**
 * The Icon Service convers FontAwesome Icons into Material Icon Format.
 */
@Injectable({
  providedIn: 'root',
})
export class IconService {
  private valid = false;

  /**
   * Constructor
   */
  constructor(
    readonly iconRegistry: MatIconRegistry,
    readonly sanitizer: DomSanitizer,
    private readonly loggerService: LoggerService,
    readonly fontAwesomeLibrary: FaIconLibrary,
  ) {
    // Make a list of all supported icons
    const iconDefinitions: IconDefinition[] = [...FarIconDefinitions, ...FasIconDefinitions, ...FabIconDefinitions];

    this.loggerService.groupCollapsed('[IconService] Registry');
    // for each of them
    for (const iconDefinition of iconDefinitions) {
      // extract all the data
      const icn = icon(iconDefinition);

      const prefix = icn.prefix;
      const name = icn.iconName;
      const svg = sanitizer.bypassSecurityTrustHtml(icn.html[0]);
      this.loggerService.log(`${prefix}:${name}`, icn);

      // register the icon with material
      iconRegistry.addSvgIconLiteralInNamespace(prefix, name, svg);

      // register the icon with fontawesome
      fontAwesomeLibrary.addIcons(iconDefinition);
    }
    this.loggerService.close();

    this.valid = true;
  }

  /**
   * Check if the name of the icon coming from JSON is supported
   */
  isSupportedIcon(iconName: string): iconName is SupportedIconNames {
    if (Object.keys(SupportedIconNamesStrings).includes(iconName as SupportedIconNames)) {
      return true;
    }

    this.loggerService.warn(`[IconService] the icon ${iconName} is not found in the icon registry.`);
    return false;
  }

  /**
   * Initialize the Icon Service
   */
  initialize() {
    return this.valid;
  }
}
