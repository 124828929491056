<div fxLayout="column" fxLayoutAlign="end stretch" #container class="mat-footer">
  @if (footerRepository.templateData$ | async; as templateData) {
    @if (templateData.showFooter) {
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="end stretch" fxLayoutAlign.gt-xs="space-between center">
        <div fxFlex.gt-xs="32" fxFlex>
          @if (templateData.sharing; as sharing) {
            <app-social-sharing [config]="sharing"></app-social-sharing>
          }
        </div>
        <div fxFlex.gt-xs="32" fxFlex>
          @if (templateData.author; as author) {
            <app-footer-author [config]="author"></app-footer-author>
          }
        </div>
        <div fxFlex.gt-xs="32" fxFlex>
          @if (templateData.branding; as branding) {
            <app-footer-branding [config]="branding"></app-footer-branding>
          }
        </div>
      </div>
    }
    @if (templateData.termsConditions; as termsConditions) {
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <app-button fxFlex="100" [config]="termsConditions"></app-button>
      </div>
    }
  }
</div>
