import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterRepository } from '../../../services/footer/footer.repository';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../../../dynamic/components/button/button.component';
import { FooterBrandingComponent } from '../footer-branding/footer-branding.component';
import { FooterAuthorComponent } from '../footer-author/footer-author.component';
import { SocialSharingComponent } from '../../../dynamic/components/social-sharing/social-sharing.component';
import { FlexModule } from '@ngbracket/ngx-layout/flex';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, SocialSharingComponent, FooterAuthorComponent, FooterBrandingComponent, ButtonComponent, AsyncPipe],
})
/**
 * The Footer is a specialized container used for displaying specific items at the bottom of the screen.
 */
export class FooterComponent {
  /**
   * Constructor
   */
  constructor(public readonly footerRepository: FooterRepository) {}
}
