import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFooterAuthor } from 'src/app/api/modules/core/components/static/IFooterAuthor';
import { FooterAuthorRepository } from './footer-author.repository';
import { AsyncPipe } from '@angular/common';
import { ImageComponent } from '../../../dynamic/components/image/image.component';
import { AbstractLinkComponent } from '../../abstract/abstract-link/abstract-link.component';

@Component({
  selector: 'app-footer-author',
  templateUrl: './footer-author.component.html',
  styleUrls: ['./footer-author.component.scss'],
  providers: [FooterAuthorRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AbstractLinkComponent, ImageComponent, AsyncPipe],
})
/**
 * The Footer Author component exists within a Footer.
 * It's purpose is to display who made the app. "Made by Clark" with an image for example.
 */
export class FooterAuthorComponent {
  /**
   * The Configuration required for this component to function
   */
  private _config: IFooterAuthor;
  @Input()
  set config(configuration: IFooterAuthor) {
    this._config = configuration;
    this.footerAuthorRepository.applyConfiguration(configuration);
  }
  get config(): IFooterAuthor {
    return this._config;
  }

  constructor(public readonly footerAuthorRepository: FooterAuthorRepository) {}
}
