import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { map } from 'rxjs';
import { IFooterAuthor } from 'src/app/api/modules/core/components/static/IFooterAuthor';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';

/**
 * The Default State
 */
function initialState(): IFooterAuthor {
  return {
    image: undefined,
    text: undefined,
    link: undefined,
  };
}

/**
 * The Store used for a {@link FooterAuthorComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class FooterAuthorRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `footer-author-${uniqueId()}`,
    },
    withProps<IFooterAuthor>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IFooterAuthor) {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.image = configuration?.image;
        state.text = configuration?.text;
        state.link = configuration?.link;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Contains the branding image
   *
   * Optionally shows the branding of who made this configuration
   *
   * The default is undefined
   */
  private _image$ = this.store.pipe(select((state) => state.image));

  /**
   * Contains the branding text
   *
   * The default is undefined
   */
  private _text$ = this.store.pipe(select((state) => state.text));

  /**
   * Contains the branding link
   *
   * The default is undefined
   */
  private _link$ = this.store.pipe(select((state) => state.link));

  /**
   * The Template Data
   */
  templateData$ = ElfCombineQueries([this._image$, this._text$, this._link$]).pipe(map(([image, text, link]) => ({ image, text, link })));
}
