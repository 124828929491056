import { LoggerService } from '@angular-ru/cdk/logger';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { DigitaServiceError, QueryParams } from '@digitaservice/utils';
import { cloneDeep, omit } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest } from 'rxjs';
import { IInitializeDataClient } from './api/modules/core/network/IInitializeData';
import { DigitaServiceInputs } from './app-global-callbacks';
import { AppService } from './modules/core/services/application/application.service';
import { AppConfigRepository } from './modules/core/services/configuration/app-config.repository';
import { DigitaServiceAPIService } from './modules/core/services/network/digitaservice-api.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializeService {
  /**
   * The Application Component will use the initiailize service
   */
  constructor(
    private readonly apiService: DigitaServiceAPIService,
    private readonly appService: AppService,
    private readonly loggerService: LoggerService,
    private readonly appConfigRepository: AppConfigRepository,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {}

  /**
   * Initiailize the Application.
   *
   * This gathers a variety of inputs, the order matters, since those at the bottom of the order override properties found earlier.
   *
   * For example if you were to set `slug` on `window.DigitaService.inputs` but also provide it in the URL.  Then the URL takes priority.
   *
   * 1. dynamics - these are created by JavaScript
   * 2. inputs - these are created by the window.DigitaService.inputs (server generated page)
   * 3. params - these are found in the URL as parameters.
   *
   * The property in the environment files `environment.bypassInitialize` when set to true,
   * will still call initialize, the only difference is that it ignores `destination` and
   * continues to the URL.
   */
  initialize() {
    const prefix = '[InitializeService]';

    this.loggerService.group(prefix);

    // gather all of the inputs from the page as taken from window.DigitaService.inputs
    const inputs = this.gatherInputs();

    // gather all of the URL parameters
    const params = this.gatherParameters();

    // merge the captured data together to build a single object
    const merged = this.mergeConfiguration(inputs, params);

    // add any missing concerns
    const configuration = this.qAConfig(merged);

    // print the objects
    this.loggerService.group('Gather Environment Data');
    this.loggerService.log('inputs :', inputs);
    this.loggerService.log('params :', params);
    this.loggerService.log('merged :', merged);
    this.loggerService.log('config :', configuration);

    // save the configuration data object to the store
    this.appConfigRepository.initialize(configuration);

    // remove the apiroot from the post since it isn't needed
    const requestConf = omit(configuration, ['apiroot']);
    this.loggerService.log('omit   :', requestConf);

    // create a data object for the post request
    const payload = this.constructPayload(requestConf);
    this.loggerService.log('payload:', payload);

    this.loggerService.close();
    this.loggerService.close();

    // go to initialize
    return combineLatest([this.appConfigRepository.config$, this.apiService.initialize(payload)]);
  }

  /**
   * Should `window.DigitaService.inputs` exist, then its values are returned.
   */
  private gatherInputs(): DigitaServiceInputs {
    if (window.DigitaService && window.DigitaService.inputs) {
      return cloneDeep(window.DigitaService.inputs);
    } else {
      return {};
    }
  }

  /**
   * Should any URL Parameters exist, they will be copied to an object.
   */
  private gatherParameters(): DigitaServiceInputs {
    // Query Params will capture the URL and it's parameters.
    const queryParams = new QueryParams();

    // construct a data object out of the params
    const data = cloneDeep(queryParams.data);

    // because the sharing URL came from the url, it should be decoded
    if (data['sharingurl']) {
      data['sharingurl'] = decodeURIComponent(data['sharingurl']);
    }

    return data || {};
  }

  /**
   * Takes all of the Input Properties and constructs an organised final map object.
   *
   * This Object is saved to the store and it is used to initiailize with the server who expects it.
   *
   * @param inputs - an object that was found within window.DigitaService.inputs
   * @param params - an object that found with url parameters.
   */
  private mergeConfiguration(inputs: object, params: object): DigitaServiceInputs {
    let data: DigitaServiceInputs = {};
    data = this.merger(inputs, data);
    data = this.merger(params, data, 'window.DigitaService.inputs');
    return data;
  }

  /**
   * Deep Merge 2 JS Objects.
   * @param copyFrom - the object to copy stuff from
   * @param copyTo - the object to copy stuff too
   * @param warn - where was the potential duplicate found?
   */
  private merger(copyFrom: object, copyTo: object, warn?: string): DigitaServiceInputs {
    // can't copy nothing
    if (!copyFrom) {
      copyFrom = {};
    }
    const keyCount = Object.keys(copyFrom);
    keyCount.forEach((key) => {
      if (warn) {
        if (copyTo[key]) {
          this.loggerService.warn(
            `The key "${key}" already appeared in ${warn} and has been overridden from "${key}:${copyTo[key]}" to "${key}:${copyFrom[key]}"`,
          );
        }
      }
      copyTo[key] = cloneDeep(copyFrom[key]);
    });

    return cloneDeep(copyTo);
  }

  /**
   * Turns DigitaService Inputs into a Request
   *
   * @param config - the config about to be passed to the initiailize endpoint
   */
  private qAConfig(config: DigitaServiceInputs): DigitaServiceInputs {
    // if there is no sharing URL then one must be provided
    if (!config.sharingurl) {
      config.sharingurl = this.appService.getAddress();
    }

    // if there is no apiroot then the one from the build environment must be used
    if (!config.apiroot) {
      this.loggerService.error('[InitializeService] No apiRoot Provided.');
      throw new DigitaServiceError('[InitializeService] No apiRoot Provided.');
    }

    // if no config slug is provided
    if (!config.slug) {
      this.loggerService.error('[InitializeService] No slug Provided.');
      throw new DigitaServiceError('[InitializeService] No slug Provided.');
    }

    return config;
  }

  /**
   * Constructs the final payload options.
   *
   * @param config - the configuration provided
   */
  private constructPayload(config: DigitaServiceInputs) {
    // build client captured data
    const client: IInitializeDataClient = {
      device: {},
    };

    // device information.
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    client.device.isDesktop = this.deviceDetectorService.isDesktop();
    client.device.isMobile = this.deviceDetectorService.isMobile();
    client.device.isTablet = this.deviceDetectorService.isTablet();
    client.device.isUnknown = client.device.isDesktop === false && client.device.isMobile === false && client.device.isTablet === false;
    client.device.browser = deviceInfo.browser;
    client.device.browserVersion = deviceInfo.browser_version;
    client.device.os = deviceInfo.os;
    client.device.osVersion = deviceInfo.os_version;
    client.device.userAgent = deviceInfo.userAgent;

    // languages
    if (navigator.languages) {
      client.languages = [];
      navigator.languages.forEach((value) => {
        client.languages.push(value);
      });
    }
    if (navigator.language) {
      client.language = navigator.language;
    }

    // user media
    if (navigator.mediaDevices?.getUserMedia) {
      client.usermedia = true;
    } else {
      client.usermedia = false;
    }

    // does the user prefer dark mode?
    const prefersDark = this.breakpointObserver.isMatched('(prefers-color-scheme: dark)');
    // does the user prefer high contrast mode?
    const prefersHighContrast =
      this.breakpointObserver.isMatched('(prefers-contrast: more)') || this.breakpointObserver.isMatched('(forced-colors: active)');

    client.theme = {
      prefersDark,
      prefersHighContrast,
    };

    let data = {};
    data = this.merger(config, data);
    data = this.merger({ client }, data);
    return data;
  }
}
