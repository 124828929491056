@if (footerAuthorRepository.templateData$ | async; as templateData) {
  @if (templateData.link; as link) {
    <div>
      <app-abstract-link [config]="link">
        <div class="wrapper">
          @if (templateData.image; as image) {
            <app-image [config]="image" [disableLoadingProgress]="true"></app-image>
          }

          @if (templateData.text; as text) {
            <span class="mat-body-small">{{ text }}</span>
          }
        </div>
      </app-abstract-link>
    </div>
  } @else {
    <div class="wrapper">
      @if (templateData.image; as image) {
        <app-image [config]="image" [disableLoadingProgress]="true"></app-image>
      }

      @if (templateData.text; as text) {
        <span class="mat-body-small">{{ text }}</span>
      }
    </div>
  }
}
