import { Injectable } from '@angular/core';
import { IShellFGEffectsConfettis } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfettis';
import { ShellFGEffectsConfettisRepository } from './shell-fg-effects-confettis.repository';

/**
 * The ShellFGEffectsConfettisService is used for each {@link ShellFGEffectsConfettisComponent}.
 */
@Injectable()
export class ShellFGEffectsConfettisService {
  /**
   * Constructor
   */
  constructor(private readonly store: ShellFGEffectsConfettisRepository) {}

  /**
   * Applies the incoming configuration to the model.
   */
  applyInitialize(configuration?: IShellFGEffectsConfettis) {
    this.store.applyInitialize(configuration);
  }

  /**
   * Is the effect complete?
   */
  applyComplete() {
    this.store.applyComplete();
  }
}
