import { LoggerService } from '@angular-ru/cdk/logger';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { catchError, first, of, Subscription, switchMap, timer } from 'rxjs';
import { DrimifyCustomTokenKeys } from 'src/app/api/modules/core/components/theme/tokens/DrimifyTokens';
import { MaterialColorTokenKeys } from 'src/app/api/modules/core/components/theme/tokens/MaterialColorTokens';
import { MaterialTypographyTokenKeys } from 'src/app/api/modules/core/components/theme/tokens/MaterialTypographyTokens';
import { ButtonComponent } from '../../../dynamic/components/button/button.component';
import { ThemeService } from '../../../services/theme/theme.service';
import { SystemVariableInfo } from './dev-panel.model';
import { DevPanelRepository } from './dev-panel.repository';

// Extract the keys of the Material System Colors
const materialColorVariables = Object.keys(MaterialColorTokenKeys) as (keyof typeof MaterialColorTokenKeys)[];

// Extract the keys of the Material System Typography
const materialTypographyVariables = Object.keys(MaterialTypographyTokenKeys) as (keyof typeof MaterialTypographyTokenKeys)[];

// Extract the keys of the Drimify Custom Variables
const drimifyCustomVariables = Object.keys(DrimifyCustomTokenKeys) as (keyof typeof DrimifyCustomTokenKeys)[];

@Component({
  selector: 'app-dev-panel',
  templateUrl: './dev-panel.component.html',
  styleUrls: ['./dev-panel.component.scss'],
  providers: [DevPanelRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, ButtonComponent, AsyncPipe],
})
/**
 * The Dev Panel is just a throw away test component utility.
 */
export class DevPanelComponent {
  // Regex pattern for valid CSS colors
  static cssColorPattern =
    /^(#([a-fA-F0-9]{3}){1,2}$|rgba?\((\d{1,3}%?,\s?){3}(\d(\.\d+)?|1|0)\)$|hsl(a)?\(\d{1,3},\s?\d{1,3}%,\s?\d{1,3}%(\s?\/\s?(0(\.\d+)?|1))?\))$/;

  /**
   * The System Colors Subscription
   */
  private materialSystemColorVariablesSubscription: Subscription | null = null;
  private materialSystemTypographyVariablesSubscription: Subscription | null = null;
  private drimifyVariablesSubscription: Subscription | null = null;

  /**
   * Constructor
   */
  constructor(
    private readonly themeService: ThemeService,
    private readonly loggerService: LoggerService,
    public readonly devPanelRepository: DevPanelRepository,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    console.log(materialColorVariables, materialTypographyVariables, drimifyCustomVariables);
  }

  /**
   * Toggle the main dev panel.
   */
  togglePanel() {
    this.devPanelRepository.applyTogglePanel();
    this.processMaterialSystemColorVariables();
    this.processMaterialSystemTypographyVariables();
    this.processDrimifyVariables();
  }

  /**
   * Toggle the dark mode.
   */
  onDevToggleDarkMode() {
    this.themeService.toggleDarkMode();
    this.processMaterialSystemColorVariables();
    this.processMaterialSystemTypographyVariables();
    this.processDrimifyVariables();
  }

  /**
   * Toggle the Material Colors.
   */
  onDevToggleMaterialColors() {
    this.devPanelRepository.applyToggleMaterialColors();
    this.processMaterialSystemColorVariables();
  }

  /**
   * Process the Material System Color Variables.
   */
  private processMaterialSystemColorVariables() {
    // Unsubscribe any previous subscription
    this.materialSystemColorVariablesSubscription?.unsubscribe();

    // Start a new timer
    this.materialSystemColorVariablesSubscription = timer(500)
      .pipe(
        first(),
        switchMap(() => {
          // Safely get the computed style
          const computedStyle = this.document.defaultView?.getComputedStyle(this.document.documentElement);
          if (!computedStyle) {
            throw new Error('Failed to retrieve computed styles');
          }

          // Map each variable to its value and collect into an array
          const styleValues: SystemVariableInfo[] = materialColorVariables.map((key) => ({
            name: key,
            value: computedStyle.getPropertyValue(key),
          }));

          return of(styleValues);
        }),
        catchError((error) => {
          this.loggerService.error('Error processing system variable colors:', error);
          return of([]);
        }),
      )
      .subscribe((styleValues: SystemVariableInfo[]) => {
        this.devPanelRepository.applyMaterialColors(styleValues);
      });
  }

  /**
   * Toggle the Material Typography.
   */
  onDevToggleMaterialTypography() {
    this.devPanelRepository.applyToggleMaterialTypography();
    this.processMaterialSystemTypographyVariables();
  }

  /**
   * Process the Material System Typography Variables.
   */
  private processMaterialSystemTypographyVariables() {
    // Unsubscribe any previous subscription
    this.materialSystemTypographyVariablesSubscription?.unsubscribe();

    // Start a new timer
    this.materialSystemTypographyVariablesSubscription = timer(500)
      .pipe(
        first(),
        switchMap(() => {
          // Safely get the computed style
          const computedStyle = this.document.defaultView?.getComputedStyle(this.document.documentElement);
          if (!computedStyle) {
            throw new Error('Failed to retrieve computed styles');
          }

          // Map each variable to its value and collect into an array
          const styleValues = materialTypographyVariables.map((key) => ({
            name: key,
            value: computedStyle.getPropertyValue(key),
          }));

          return of(styleValues);
        }),
        catchError((error) => {
          this.loggerService.error('Error processing system variable typography:', error);
          // Return an empty array in case of error
          return of([]);
        }),
      )
      .subscribe((styleValues) => {
        this.devPanelRepository.applyMaterialTypography(styleValues);
      });
  }

  /**
   * Toggle the Drimify Variables.
   */
  onDevToggleDrimifyVariables() {
    this.devPanelRepository.applyToggleDrimifyVariables();
    this.processDrimifyVariables();
  }

  /**
   * Process the Drimify Variables.
   */
  private processDrimifyVariables() {
    // Unsubscribe any previous subscription
    this.drimifyVariablesSubscription?.unsubscribe();

    // Start a new timer
    this.drimifyVariablesSubscription = timer(500)
      .pipe(
        first(),
        switchMap(() => {
          // Safely get the computed style
          const computedStyle = this.document.defaultView?.getComputedStyle(this.document.documentElement);
          if (!computedStyle) {
            throw new Error('Failed to retrieve computed styles');
          }

          // Map each variable to its value and collect into an array
          const styleValues = drimifyCustomVariables.map((key) => ({
            name: key,
            value: computedStyle.getPropertyValue(key),
          }));

          return of(styleValues);
        }),
        catchError((error) => {
          this.loggerService.error('Error processing drimify variable colors:', error);
          // Return an empty array in case of error
          return of([]);
        }),
      )
      .subscribe((styleValues) => {
        this.devPanelRepository.applyDrimifyVariables(styleValues);
      });
  }

  /**
   * Check if the color is a valid CSS color.
   *
   * @param color - The color to validate.
   * @returns true if the color is a valid CSS color, false otherwise.
   */
  isValidCssColor(color: string) {
    return DevPanelComponent.cssColorPattern.test(color.trim());
  }
}
