import versions from 'src/environments/version';

/**
 * The Sentry DSN for the application.
 */
export const SentryDSN = 'https://8124aa634b0de695a495ce6e394d693e@o401539.ingest.us.sentry.io/4508172687310848';

/**
 * Generates the Sentry release version string.
 *
 * The format is: @package@version+commitHash
 *
 * @returns The Sentry release version as a string: @package@version+commitHash.
 */
export const SentryReleaseName = () => {
  return `app@${versions.version}`;
};

/**
 * The URLs to deny from Sentry error reporting.
 */
export const SentryDenyUrls = [
  'localhost',
  'https://dev-front.drimify.com',
  'https://front.drimify.com',
  'https://staging-app.drimify.local',
  'http://staging-app.drimify.local',
  'https://staging-app.drimify.com',
];

/**
 * The URLs to allow for Sentry error reporting.
 */
export const SentryAllowURLs = [/https?:\/\/([a-zA-Z0-9-]+\.)?drimify\.com/, /https?:\/\/([a-zA-Z0-9-]+\.)?cdn8\.app/];
