import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShellBackgroundEntityComponent } from './shell-background-entity/shell-background-entity.component';
import { ShellBackgroundEntityModel } from './shell-background-entity/shell-background-entity.model';
import { shellBackgroundAnimation } from './shell-background.animations';
import { ShellBackgroundRepository } from './shell-background.repository';

/**
 * The Shell Background is the main Component responsible for managing background subsystems.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-shell-background',
  templateUrl: './shell-background.component.html',
  styleUrls: ['./shell-background.component.scss'],
  animations: [shellBackgroundAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ShellBackgroundEntityComponent, AsyncPipe],
})
export class ShellBackgroundComponent {
  /**
   * Contains the background and overlay logic.
   */
  constructor(public readonly shellBackgroundRepository: ShellBackgroundRepository) {}

  /**
   * Once a new system has been added to the display list, we can tell the store
   * about this.
   */
  animationAddedDone() {
    this.shellBackgroundRepository.applySuccessfulBackgroundAddition();
  }

  /**
   * Prevents the component redrawing the list when the state updates
   */
  trackByFn(index: number, item: ShellBackgroundEntityModel) {
    return item?.id || index;
  }
}
