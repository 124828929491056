import {
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  BrowserClient,
  dedupeIntegration,
  defaultStackParser,
  eventFiltersIntegration,
  functionToStringIntegration,
  globalHandlersIntegration,
  httpContextIntegration,
  linkedErrorsIntegration,
} from '@sentry/angular';
import { SentryDenyUrls, SentryReleaseName } from './SentryClientShared';

/**
 * @returns A new Sentry client for the local environment.
 */
export const SentryClientLocal = () => {
  const client = new BrowserClient({
    dsn: null,
    debug: true,
    release: SentryReleaseName(),
    environment: 'local',
    sampleRate: 0.1,
    tracesSampleRate: 0,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    ignoreErrors: [],
    ignoreTransactions: [],
    denyUrls: SentryDenyUrls,
    maxValueLength: 250,
    normalizeDepth: 3,
    normalizeMaxBreadth: 1000,
    sendClientReports: false,
    integrations: [
      breadcrumbsIntegration(),
      browserApiErrorsIntegration(),
      dedupeIntegration(),
      functionToStringIntegration(),
      globalHandlersIntegration(),
      httpContextIntegration(),
      eventFiltersIntegration(),
      linkedErrorsIntegration(),
    ],
    stackParser: defaultStackParser,
    transport: null,
  });
  return client;
};
