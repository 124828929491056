import { Routes } from '@angular/router';
import { digitaServiceRouteResolver } from './modules/core/services/network/digitaservice-route-resolver';

/**
 * Top Level Routes
 */
export const AppRoutes: Routes = [
  {
    path: 'content',
    children: [
      {
        path: 'dynamic',
        children: [
          {
            path: '**',
            loadComponent: () => import('./modules/core/dynamic/screens/screen/screen.component').then((m) => m.ScreenComponent),
            resolve: {
              screen: digitaServiceRouteResolver,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'plugin',
    children: [
      {
        path: '**',
        loadComponent: () => import('./modules/core/dynamic/screens/screen/screen.component').then((m) => m.ScreenComponent),
        resolve: {
          screen: digitaServiceRouteResolver,
        },
      },
    ],
  },
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo-routes').then((m) => m.DemoRoutes),
  },
  {
    path: 'forms',
    children: [
      {
        path: 'dynamic',
        loadChildren: () => import('./modules/forms/dynamic-controls/dynamic-controls.module').then((m) => m.DynamicControlsModule),
      },
    ],
  },
  {
    path: 'ask',
    loadChildren: () => import('./modules/ask/ask.routes').then((m) => m.AskRoutes),
  },
  {
    path: 'experiences',
    children: [
      {
        path: 'chatbot',
        loadChildren: () => import('./modules/experiences/chatbot/chatbot.routes').then((m) => m.ChatbotRoutes),
      },
      {
        path: 'dynamic-path',
        loadChildren: () => import('./modules/experiences/dynamic-path/dynamic-path.routes').then((m) => m.DynamicPathRoutes),
      },
      {
        path: 'media-contest',
        loadChildren: () => import('./modules/experiences/media-contest/media-contest.routes').then((m) => m.MediaContestRoutes),
      },
    ],
  },
  {
    path: 'activities',
    children: [
      {
        path: 'team-picker',
        loadChildren: () => import('./modules/activities/team-picker/team-picker.routes').then((m) => m.TeamPickerRoutes),
      },
    ],
  },
];
