<!--
    A Div is required to set the main direction axis for responsiveness
-->
<div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="100">
  <!--
      This container is controlled by the height measurement system

      The div was added purely to set a minheight without competing with inner flexbox and animation
     -->
  <div #outletContainer id="outlet-container" fxLayout="row" fxLayoutAlign="center start">
    <div
      id="router-outlet-container"
      [@routeAnimations]="prepareRoute(outlet)"
      [fxFlex]="(appRepository.outletLayoutColumnXS$ | async) || '1 0 300px'"
      [fxFlex.gt-xs]="(appRepository.outletLayoutColumnGTXS$ | async) || '0 0 600px'"
    >
      <!-- New Routes appear under this router outlet -->
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
</div>
