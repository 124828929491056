<div fxLayout="row" fxLayoutAlign="start">
  @if (devPanelRepository.templateData$ | async; as templateData) {
    <app-button [config]="templateData.panelButton" (buttonClick)="togglePanel()"></app-button>

    @if (templateData.isOpen) {
      <div fxLayout="row" fxLayoutAlign="start">
        <!-- Dark Mode Toggle -->
        <app-button
          [config]="templateData.darkModeToggleButton"
          [tooltip]="templateData.isDarkModeEnabled ? 'Disable Dark Mode' : 'Enable Dark Mode'"
          (buttonClick)="onDevToggleDarkMode()"
          class="spacer"
        ></app-button>

        <!-- High Contrast Toggle -->
        <app-button
          [config]="templateData.highContrastToggleButton"
          [tooltip]="templateData.isHighContrastModeEnabled ? 'Disable High Contrast Mode' : 'Enable High Contrast Mode'"
          (buttonClick)="onDevToggleHighContrastMode()"
          class="spacer"
        ></app-button>

        <!-- Material Colors -->
        @if (templateData.materialColors; as materialColors) {
          <app-button [config]="materialColors.button" (buttonClick)="onDevToggleMaterialColors()" class="spacer"></app-button>
          @if (materialColors.isOpen) {
            <div class="variable-panel variable-color-panel spacer">
              @for (item of materialColors.variables; track item.name) {
                <div class="variable-item">
                  <span class="variable-name">var({{ item.name }});</span>
                  <span class="variable-color-box" [style.background-color]="item.value"></span>
                  <span class="variable-value">{{ item.value }}</span>
                </div>
              }
            </div>
          }
        }

        <!-- Material Typography -->
        @if (templateData.materialTypography; as materialTypography) {
          <app-button [config]="materialTypography.button" (buttonClick)="onDevToggleMaterialTypography()" class="spacer"></app-button>
          @if (materialTypography.isOpen) {
            <div class="variable-panel variable-typography-panel spacer">
              @for (item of materialTypography.variables; track item.name) {
                <div class="variable-item">
                  <span class="variable-name">var({{ item.name }});</span>
                  <span class="variable-value">{{ item.value }}</span>
                </div>
              }
            </div>
          }
        }

        <!-- Drimify Custom Variables -->
        @if (templateData.drimifyVariables; as drimifyVariables) {
          <app-button [config]="drimifyVariables.button" (buttonClick)="onDevToggleDrimifyVariables()" class="spacer"></app-button>
          @if (drimifyVariables.isOpen) {
            <div class="variable-panel variable-custom-panel spacer">
              @for (item of drimifyVariables.variables; track item.name) {
                <div class="variable-item">
                  <span class="variable-name">var({{ item.name }});</span>
                  @if (isValidCssColor(item.value)) {
                    <span class="variable-color-box" [style.background-color]="item.value"></span>
                  }
                  <span class="variable-value">{{ item.value }}</span>
                </div>
              }
            </div>
          }
        }
      </div>
    }
  }
</div>
