/* eslint-disable @angular-eslint/no-output-native */

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import confetti from 'canvas-confetti';
import { Subscription, from, switchMap } from 'rxjs';
import { IShellFGEffectsConfetti } from 'src/app/api/modules/core/components/effects/foreground/IShellFGEffectConfetti';
import { ShellFGEffectsConfettiRepository } from './shell-fg-effects-confetti.repository';
import { ShellFGEffectsConfettiService } from './shell-fg-effects-confetti.service';

/**
 * The Shell Background is the main Component responsible for managing background subsystems.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-shell-fg-effects-confetti',
  templateUrl: './shell-fg-effects-confetti.component.html',
  styleUrls: ['./shell-fg-effects-confetti.component.scss'],
  providers: [ShellFGEffectsConfettiService, ShellFGEffectsConfettiRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShellFGEffectsConfettiComponent implements AfterViewInit, OnDestroy {
  /**
   * The configuration for the confetti effect
   */
  private _config: IShellFGEffectsConfetti | undefined = undefined;
  @Input()
  set config(config: IShellFGEffectsConfetti) {
    this._config = config;
    this.service.applyInitialize(config);
  }
  get config() {
    return this._config;
  }

  /**
   * Occurs when the effect is complete.
   *
   * Emits the effectID.
   */
  @Output() complete = new EventEmitter<string>();

  /**
   * Holds the subscription for teardown.
   */
  private subscriptions = new Subscription();

  /**
   * Constructor
   */
  constructor(
    private readonly service: ShellFGEffectsConfettiService,
    public readonly query: ShellFGEffectsConfettiRepository,
  ) {}

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // apply the configuration and create the confetti effect
    const confettiSub = this.query.confettiConfig$
      .pipe(
        switchMap((config) => {
          return from(confetti(config));
        }),
      )
      .subscribe(() => {
        this.service.applyComplete();
      });
    this.subscriptions.add(confettiSub);

    // listen for complete
    const completeSub = this.query.completeConfig$.subscribe((effectID) => {
      this.complete.next(effectID);
      this.complete.complete();
    });
    this.subscriptions.add(completeSub);
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
    this.complete?.complete();
  }
}
