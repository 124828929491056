import {
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  BrowserClient,
  dedupeIntegration,
  defaultStackParser,
  eventFiltersIntegration,
  functionToStringIntegration,
  globalHandlersIntegration,
  httpContextIntegration,
  linkedErrorsIntegration,
  makeFetchTransport,
  rewriteFramesIntegration,
} from '@sentry/angular';
import { SentryDenyUrls, SentryDSN, SentryReleaseName } from './SentryClientShared';

/**
 * @returns A new Sentry client for the development environment.
 */
export const SentryClientDevelopment = () => {
  const client = new BrowserClient({
    dsn: SentryDSN,
    debug: true,
    release: SentryReleaseName(),
    environment: 'development',
    sampleRate: 0.01,
    tracesSampleRate: 0,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    ignoreErrors: [],
    ignoreTransactions: [],
    denyUrls: SentryDenyUrls,
    maxValueLength: 250,
    normalizeDepth: 3,
    normalizeMaxBreadth: 1000,
    sendClientReports: false,
    integrations: [
      breadcrumbsIntegration(),
      browserApiErrorsIntegration(),
      dedupeIntegration(),
      functionToStringIntegration(),
      globalHandlersIntegration(),
      httpContextIntegration(),
      eventFiltersIntegration(),
      linkedErrorsIntegration(),
      rewriteFramesIntegration(),
    ],
    stackParser: defaultStackParser,
    transport: makeFetchTransport,
  });

  return client;
};
