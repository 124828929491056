import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DigitaServiceError } from '@digitaservice/utils';
import { SentryErrorHandler } from '@sentry/angular';
import { createDigitaServiceError } from './app-error';
import { AppService } from './modules/core/services/application/application.service';
import { DialogService } from './modules/core/services/dialog.service';
import { SnackbarService } from './modules/core/services/snackbar.service';

/**
 * Handles all final global errors in the system
 *
 * See https://github.com/scttcper/ngx-toastr/issues/179#issuecomment-325724269
 */
@Injectable()
export class AppErrorHandler extends SentryErrorHandler {
  /**
   * Constructor
   *
   * Because the ErrorHandler is created before the providers, we need the injector
   */
  constructor(private readonly injector: Injector) {
    super();
  }

  /**
   * Handle all Errors that slip through the cracks.
   *
   * @param error - Either a Runtime Error or a Network Error
   */
  override handleError(error: Error | DigitaServiceError | HttpErrorResponse) {
    // dismiss any open dialogs or snackbars
    const dialogService = this.injector.get<DialogService>(DialogService);
    dialogService?.dismiss();
    const snackbarService = this.injector.get<SnackbarService>(SnackbarService);
    snackbarService?.dismiss();

    // process the errors in different ways depending on the type
    if (error instanceof DigitaServiceError) {
      this.handleDigitaServiceError(error as DigitaServiceError);
    } else if (error instanceof HttpErrorResponse) {
      this.handleHTTPError(error as HttpErrorResponse);
    } else {
      this.handleStandardError(error as Error);
    }

    // Disabled in Favor of Sentry. See https://drimify.atlassian.net/browse/APP-1050
    /*
      // instana error report
      try {
        if (ineum) {
          ineum('reportError', error);
          ineum('terminateSession');
        }
      } catch (e) {
        // will fail if instana is not loaded
      }
    */

    // Handle the error with Sentry
    super.handleError(error);
  }

  /**
   * Handles a generic Error
   *
   * @param error - the generic error
   */
  private handleStandardError(error: Error) {
    // Default error message if empty
    const errorMessage = error.message || 'An unknown error occurred';

    // Try to get the method and system from the stack trace if available
    const stackLines = error.stack ? error.stack.split('\n') : [];
    const defaultSystem = 'Unknown System';
    const defaultMethod = 'Unknown Method';

    // Extract the system and method from the stack trace if available
    const system = stackLines.length > 1 ? this.extractSystemFromStack(stackLines[1]) : defaultSystem;
    const method = stackLines.length > 1 ? this.extractMethodFromStack(stackLines[1]) : defaultMethod;

    // create a DigitaServiceError
    const digitaServiceError = createDigitaServiceError(system, method, errorMessage, 'internal');

    this.dispatchToStore(digitaServiceError);
  }

  /**
   * Extracts the system or file name from the stack trace line
   */
  private extractSystemFromStack(stackLine: string): string {
    const match = stackLine.match(/at (\S+)/);
    return match ? match[1] : 'Unknown System';
  }

  /**
   * Extracts the method from the stack trace line
   */
  private extractMethodFromStack(stackLine: string): string {
    const match = stackLine.match(/\.(\w+)\s?\(/);
    return match ? match[1] : 'Unknown Method';
  }

  /**
   * Handles an HTTP Error Response.
   *
   * @param error - the HTTP Error Response
   */
  private handleHTTPError(error: HttpErrorResponse) {
    // Create a detailed error message using status, statusText, and URL
    const errorMessage = `HTTP Error ${error.status}: ${error.statusText} - ${error.url || 'Unknown URL'}`;

    // Use default values for system and method, since the error comes from an HTTP request
    const system = 'HttpClient';
    const method = 'request';

    // Create a DigitaServiceError using the createDigitaServiceError function
    const digitaServiceError = createDigitaServiceError(system, method, errorMessage, 'network');

    // Dispatch the error to the store
    this.dispatchToStore(digitaServiceError);
  }

  /**
   * Handles a DigitaService Error Data.
   *
   * @param error - the DigitaService Error
   */
  private handleDigitaServiceError(error: DigitaServiceError) {
    this.dispatchToStore(error);
  }

  /**
   * Dispatches data to the store
   *
   * @param errorAction - the error action to dispatch
   */
  private dispatchToStore(error: DigitaServiceError) {
    const store = this.injector.get<AppService>(AppService);
    store.registerError(error);
  }
}
