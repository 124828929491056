import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DevPanelModel, SystemVariableInfo } from './dev-panel.model';

/**
 * Creates and returns the initial state for the developer panel.
 *
 * @returns The default state of the developer panel.
 */
function initialState(): DevPanelModel {
  return {
    isOpen: false,
    isDarkModeEnabled: false,
    isHighContrastModeEnabled: false,
    panelButton: {
      selector: 'app-button',
      type: 'button',
      style: 'mini-fab',
      iconPrefix: {
        name: 'fas:code',
      },
    },
    darkModeToggleButton: {
      selector: 'app-button',
      type: 'button',
      style: 'mini-fab',
      iconPrefix: {
        name: 'fas:circle-half-stroke',
      },
    },
    highContrastToggleButton: {
      selector: 'app-button',
      type: 'button',
      style: 'mini-fab',
      iconPrefix: {
        name: 'fas:eye-low-vision',
      },
    },
    materialColors: {
      button: {
        selector: 'app-button',
        type: 'button',
        style: 'mini-fab',
        iconPrefix: {
          name: 'far:palette',
        },
        tooltip: 'Theme Color Variables',
      },
      isOpen: false,
      variables: [],
    },
    materialTypography: {
      button: {
        selector: 'app-button',
        type: 'button',
        style: 'mini-fab',
        iconPrefix: {
          name: 'far:font-case',
        },
        tooltip: 'Theme Typography Variables',
      },
      isOpen: false,
      variables: [],
    },
    drimifyVariables: {
      button: {
        selector: 'app-button',
        type: 'button',
        style: 'mini-fab',
        iconPrefix: {
          name: 'far:star-of-life',
        },
        tooltip: 'Drimify Custom Variables',
      },
      isOpen: false,
      variables: [],
    },
  };
}

/**
 * Repository for managing the state of the Developer Panel.
 *
 * This store is used by the {@link DevPanelComponent} in the {@link CoreModule}.
 */
@Injectable()
export class DevPanelRepository implements OnDestroy {
  /**
   * Underlying state store for the developer panel.
   */
  private store = createStore(
    {
      name: `dev-panel`,
    },
    withProps<DevPanelModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Toggles the open state of the developer panel.
   */
  applyTogglePanel() {
    this.store.update(
      ElfWrite((state) => {
        state.isOpen = !state.isOpen;
      }),
    );
  }

  /**
   * Updates the dark mode status.
   *
   * @param isDarkModeEnabled - Set to `true` to enable dark mode; otherwise, `false`.
   */
  applyDarkModeEnabled(isDarkModeEnabled: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.isDarkModeEnabled = isDarkModeEnabled;
      }),
    );
  }

  /**
   * Updates the high contrast mode status.
   *
   * @param isHighContrastModeEnabled - Set to `true` to enable high contrast mode; otherwise, `false`.
   */
  applyHighContrastModeEnabled(isHighContrastModeEnabled: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.isHighContrastModeEnabled = isHighContrastModeEnabled;
      }),
    );
  }

  /**
   * Toggles the Material Colors panel open state while closing other panels.
   */
  applyToggleMaterialColors() {
    const { materialColors, materialTypography, drimifyVariables } = this.store.getValue();

    const newMaterialColors = cloneDeep(materialColors);
    newMaterialColors.isOpen = !newMaterialColors.isOpen;

    const newMaterialTypography = cloneDeep(materialTypography);
    newMaterialTypography.isOpen = false;

    const newDrimifyVariables = cloneDeep(drimifyVariables);
    newDrimifyVariables.isOpen = false;

    this.store.update(
      ElfWrite((state) => {
        state.materialColors = newMaterialColors;
        state.materialTypography = newMaterialTypography;
        state.drimifyVariables = newDrimifyVariables;
      }),
    );
  }

  /**
   * Applies the specified system variables to the Material Colors panel.
   *
   * @param systemVariables - Array of system variable details.
   */
  applyMaterialColors(systemVariables: SystemVariableInfo[]) {
    const { materialColors } = this.store.getValue();

    const newMaterialColors = cloneDeep(materialColors);
    newMaterialColors.variables = systemVariables;

    this.store.update(
      ElfWrite((state) => {
        state.materialColors = newMaterialColors;
      }),
    );
  }

  /**
   * Toggles the Material Typography panel open state while closing other panels.
   */
  applyToggleMaterialTypography() {
    const { materialColors, materialTypography, drimifyVariables } = this.store.getValue();

    const newMaterialTypography = cloneDeep(materialTypography);
    newMaterialTypography.isOpen = !newMaterialTypography.isOpen;

    const newMaterialColors = cloneDeep(materialColors);
    newMaterialColors.isOpen = false;

    const newDrimifyVariables = cloneDeep(drimifyVariables);
    newDrimifyVariables.isOpen = false;

    this.store.update(
      ElfWrite((state) => {
        state.materialTypography = newMaterialTypography;
        state.materialColors = newMaterialColors;
        state.drimifyVariables = newDrimifyVariables;
      }),
    );
  }

  /**
   * Applies the specified system variables to the Material Typography panel.
   *
   * @param systemVariables - Array of system variable details.
   */
  applyMaterialTypography(systemVariables: SystemVariableInfo[]) {
    const { materialTypography } = this.store.getValue();

    const newMaterialTypography = cloneDeep(materialTypography);
    newMaterialTypography.variables = systemVariables;

    this.store.update(
      ElfWrite((state) => {
        state.materialTypography = newMaterialTypography;
      }),
    );
  }

  /**
   * Toggles the Drimify Variables panel open state while closing other panels.
   */
  applyToggleDrimifyVariables() {
    const { materialColors, materialTypography, drimifyVariables } = this.store.getValue();

    const newDrimifyVariables = cloneDeep(drimifyVariables);
    newDrimifyVariables.isOpen = !newDrimifyVariables.isOpen;

    const newMaterialColors = cloneDeep(materialColors);
    newMaterialColors.isOpen = false;

    const newMaterialTypography = cloneDeep(materialTypography);
    newMaterialTypography.isOpen = false;

    this.store.update(
      ElfWrite((state) => {
        state.drimifyVariables = newDrimifyVariables;
        state.materialColors = newMaterialColors;
        state.materialTypography = newMaterialTypography;
      }),
    );
  }

  /**
   * Applies the specified system variables to the Drimify Variables panel.
   *
   * @param systemVariables - Array of system variable details.
   */
  applyDrimifyVariables(systemVariables: SystemVariableInfo[]) {
    const { drimifyVariables } = this.store.getValue();

    const newDrimifyVariables = cloneDeep(drimifyVariables);
    newDrimifyVariables.variables = systemVariables;

    this.store.update(
      ElfWrite((state) => {
        state.drimifyVariables = newDrimifyVariables;
      }),
    );
  }

  /**
   * Angular lifecycle hook to clean up the store when the repository is destroyed.
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Observable that emits the complete developer panel state.
   */
  templateData$ = this.store.pipe(select((state) => state));
}
