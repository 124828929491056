import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { map } from 'rxjs';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { DevPanelModel, SystemVariableInfo } from './dev-panel.model';

/**
 * The Default State
 */
function initialState(): DevPanelModel {
  return {
    isOpen: false,
    panelButton: {
      selector: 'app-button',
      type: 'button',
      style: 'mini-fab',
      iconPrefix: {
        name: 'fas:code',
      },
    },
    darkModeToggleButton: {
      selector: 'app-button',
      type: 'button',
      style: 'mini-fab',
      iconPrefix: {
        name: 'fas:circle-half-stroke',
      },
    },
    materialColors: {
      button: {
        selector: 'app-button',
        type: 'button',
        style: 'mini-fab',
        iconPrefix: {
          name: 'far:palette',
        },
      },
      isOpen: false,
      variables: [],
    },
    materialTypography: {
      button: {
        selector: 'app-button',
        type: 'button',
        style: 'mini-fab',
        iconPrefix: {
          name: 'far:font-case',
        },
      },
      isOpen: false,
      variables: [],
    },
    drimifyVariables: {
      button: {
        selector: 'app-button',
        type: 'button',
        style: 'mini-fab',
        iconPrefix: {
          name: 'far:star-of-life',
        },
      },
      isOpen: false,
      variables: [],
    },
  };
}

/**
 * The Store used for a {@link DevPanelComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class DevPanelRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `dev-panel`,
    },
    withProps<DevPanelModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Does the Panel Open and Closed
   */
  applyTogglePanel() {
    this.store.update(
      ElfWrite((state) => {
        state.isOpen = !state.isOpen;
      }),
    );
  }

  /**
   * Toggles the `isOpen` property of the material colors in the store.
   */
  applyToggleMaterialColors() {
    const { materialColors } = this.store.getValue();

    const newMaterialColors = cloneDeep(materialColors);
    newMaterialColors.isOpen = !newMaterialColors.isOpen;

    this.store.update(
      ElfWrite((state) => {
        state.materialColors = newMaterialColors;
      }),
    );
  }

  /**
   * Applies the provided system variables to the material colors in the store.
   *
   * @param {SystemVariableInfo[]} systemVariables - The array of system variables to be applied.
   */
  applyMaterialColors(systemVariables: SystemVariableInfo[]) {
    const { materialColors } = this.store.getValue();

    const newMaterialColors = cloneDeep(materialColors);
    newMaterialColors.variables = systemVariables;

    this.store.update(
      ElfWrite((state) => {
        state.materialColors = newMaterialColors;
      }),
    );
  }

  /**
   * Toggles the `isOpen` property of the material typography in the store.
   */
  applyToggleMaterialTypography() {
    const { materialTypography } = this.store.getValue();

    const newMaterialTypography = cloneDeep(materialTypography);
    newMaterialTypography.isOpen = !newMaterialTypography.isOpen;

    this.store.update(
      ElfWrite((state) => {
        state.materialTypography = newMaterialTypography;
      }),
    );
  }

  /**
   * Applies the provided system variables to the material typography in the store.
   *
   * @param {SystemVariableInfo[]} systemVariables - The array of system variables to be applied.
   */
  applyMaterialTypography(systemVariables: SystemVariableInfo[]) {
    const { materialTypography } = this.store.getValue();

    const newMaterialTypography = cloneDeep(materialTypography);
    newMaterialTypography.variables = systemVariables;

    this.store.update(
      ElfWrite((state) => {
        state.materialTypography = newMaterialTypography;
      }),
    );
  }

  /**
   * Toggles the `isOpen` property of the drimify custom variables in the store.
   */
  applyToggleDrimifyVariables() {
    const { drimifyVariables } = this.store.getValue();

    const newDrimifyVariables = cloneDeep(drimifyVariables);
    newDrimifyVariables.isOpen = !newDrimifyVariables.isOpen;

    this.store.update(
      ElfWrite((state) => {
        state.drimifyVariables = newDrimifyVariables;
      }),
    );
  }

  /**
   * Applies the provided system variables to the drimify custom variables in the store.
   *
   * @param {SystemVariableInfo[]} systemVariables - The array of system variables to be applied.
   */
  applyDrimifyVariables(systemVariables: SystemVariableInfo[]) {
    const { drimifyVariables } = this.store.getValue();

    const newDrimifyVariables = cloneDeep(drimifyVariables);
    newDrimifyVariables.variables = systemVariables;

    this.store.update(
      ElfWrite((state) => {
        state.drimifyVariables = newDrimifyVariables;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the Dev Panel Open?
   */
  private _isOpen = this.store.pipe(select((state) => state.isOpen));

  /**
   * The Panel Button Configuration
   */
  private _darkModeToggleButton$ = this.store.pipe(select((state) => state.darkModeToggleButton));

  /**
   * The Panel Button Configuration
   */
  private _panelButton$ = this.store.pipe(select((state) => state.panelButton));

  /**
   * The Material Colors System Variables Info
   */
  private _materialColors$ = this.store.pipe(select((state) => state.materialColors));

  /**
   * The Material Typography System Variables Info
   */
  private _materialTypography$ = this.store.pipe(select((state) => state.materialTypography));

  /**
   * The Drimify Custom Variables Info
   */
  private _drimifyVariables$ = this.store.pipe(select((state) => state.drimifyVariables));

  /**
   * The Template Data
   */
  templateData$ = ElfCombineQueries([
    this._isOpen,
    this._darkModeToggleButton$,
    this._panelButton$,
    this._materialColors$,
    this._materialTypography$,
    this._drimifyVariables$,
  ]).pipe(
    map(([isOpen, darkModeToggleButton, panelButton, materialColors, materialTypography, drimifyVariables]) => {
      return {
        isOpen,
        darkModeToggleButton,
        panelButton,
        materialColors,
        materialTypography,
        drimifyVariables,
      };
    }),
  );
}
