import { LoggerService } from '@angular-ru/cdk/logger';
import { AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivationEnd, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription } from 'rxjs';
import { routeAnimations } from 'src/app/app-routing-animations';
import { AppRepository } from '../../../services/application/application.repository';
import { AppService } from '../../../services/application/application.service';

@Component({
  selector: 'app-shell-outlet-container',
  templateUrl: './shell-outlet-container.component.html',
  styleUrls: ['./shell-outlet-container.component.scss'],
  animations: [routeAnimations],
  standalone: true,
  imports: [FlexModule, RouterOutlet, AsyncPipe],
})
export class ShellOutletContainerComponent implements AfterViewInit, OnDestroy {
  /**
   * Holds all subscriptions
   */
  private subscriptions: Subscription;

  /**
   * The Outlet Container is required to store it's height.
   */
  @ViewChild('outletContainer', { static: true })
  private outletContainer?: ElementRef<HTMLDivElement>;

  /**
   * Constructor
   */
  constructor(
    private readonly router: Router,
    private readonly loggerService: LoggerService,
    private readonly appService: AppService,
    public readonly appRepository: AppRepository,
  ) {
    this.subscriptions = new Subscription();
  }

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // grab the outlet container and register it with the store
    const outletContainer = this.outletContainer?.nativeElement;
    if (!outletContainer) {
      throw new Error('[Shell] - The Outlet Container was not found.');
    }
    this.appService.setPrimaryOutletContainer(outletContainer);

    // INSTANA
    let pageName: string | undefined;
    let isFirstRoute = true;

    const routerSub = this.router.events.subscribe((routerEvent) => {
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Instana Error Reporting
      // https://github.com/instana/website-monitoring-examples/tree/master/examples/defining-pages/angular-router
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////

      if (routerEvent instanceof ActivationEnd) {
        pageName = undefined;
        if (this.router.url) {
          pageName = this.router.url;
        }
      } else if (routerEvent instanceof NavigationEnd) {
        try {
          if (ineum) {
            // the first route is undefined because it is initializing
            // so this first undefined route is called initiailizing
            // and can only happen once.
            if (isFirstRoute && !pageName) {
              isFirstRoute = false;
              pageName = 'initializing';
            }
            this.loggerService.log('Instana - set page', pageName);
            ineum('page', pageName);
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
          // this will fail if instana is not available.
        }
      }

      ////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Digita Service
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // any time that a screen is about to be destroyed, update the store so
      // the outlet can be measured. This is awkward, but required since
      // preventing page collapse is difficult outside of the store.

      // on navigation start events
      if (routerEvent instanceof NavigationStart) {
        // pass it to the store for the primary outlet container to be measured.
        this.appService.registerPrepareForRouteTransition();
      }
    });
    this.subscriptions.add(routerSub);
  }

  /**
   * Prepare a Route for Animating
   * @param outlet - the outlet to target
   */
  prepareRoute(outlet: RouterOutlet) {
    if (outlet.isActivated && outlet.activatedRoute) {
      return outlet.activatedRoute.url;
    }

    return undefined;
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }
}
