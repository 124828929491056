import { LoggerService } from '@angular-ru/cdk/logger';
import { AsyncPipe, NgClass } from '@angular/common';
// import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { createDigitaServiceError } from './app-error';
import { AppInitializeService } from './app-initialize.service';
import { CookieConsentComponent } from './modules/core/components/abstract/cookie-consent/cookie-consent.component';
import { ShellBackgroundComponent } from './modules/core/components/abstract/shell-background/shell-background.component';
import { ShellFGEffectsContainerComponent } from './modules/core/components/abstract/shell-fg-effects-container/shell-fg-effects-container.component';
import { ShellLoaderComponent } from './modules/core/components/abstract/shell-loader/shell-loader.component';
import { ShellOutletContainerComponent } from './modules/core/components/abstract/shell-outlet-container/shell-outlet-container.component';
import { DevPanelComponent } from './modules/core/components/static/dev-panel/dev-panel.component';
import { FooterComponent } from './modules/core/components/static/footer/footer.component';
import { AppRepository } from './modules/core/services/application/application.repository';
import { AppService } from './modules/core/services/application/application.service';
import { CookiesRepository } from './modules/core/services/cookies/cookies.repository';
import { CookiesService } from './modules/core/services/cookies/cookies.service';
import { FooterRepository } from './modules/core/services/footer/footer.repository';
import { ThemeService } from './modules/core/services/theme/theme.service';
import { IconService } from './modules/icons/services/icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ShellBackgroundComponent,
    ShellOutletContainerComponent,
    ShellLoaderComponent,
    ShellFGEffectsContainerComponent,
    FooterComponent,
    CookieConsentComponent,
    DevPanelComponent,
    FlexModule,
    AsyncPipe,
  ],
})
export class AppComponent implements OnInit {
  /**
   * App Entry Point
   */
  constructor(
    private readonly appService: AppService,
    private readonly icon: IconService,
    private readonly initializeService: AppInitializeService,
    private readonly loggerService: LoggerService,
    private readonly themeService: ThemeService,
    private readonly cookiesService: CookiesService,
    public readonly cookiesRepository: CookiesRepository,
    public readonly appRepository: AppRepository,
    public readonly footerRepository: FooterRepository,
  ) {
    // registers gsap to handle scroll to plugin.
    // this replaces "scroll-behavior: 'smooth'" and allows
    // direct control of auto scrolling durations and easing.
    gsap.registerPlugin(ScrollToPlugin);
  }

  /**
   * Occurs when the Application has successfully bootstrapped.
   *
   * Once this step is reached, we will initialize with the server and expect
   * that to complete.
   */
  ngOnInit() {
    this.loggerService.log('[App] initializing');

    // apply the dev panel if needed
    this.appService.applyDevPanel(environment.showDevPanel);

    // initialize
    this.initializeService
      .initialize()
      .pipe(take(1))
      .subscribe(([configuration, initializeResponse]) => {
        this.loggerService.groupCollapsed('[App] Initialize Response');
        this.loggerService.log(initializeResponse);
        this.loggerService.close();

        this.appService.initialize(configuration, initializeResponse);

        // the server should be sending a valid object response
        if (!initializeResponse) {
          throw createDigitaServiceError(`App`, `Initialize`, `The server did not respond with a valid configuration.`, `config`);
        }

        // creates the icon registry
        this.icon.initialize();

        // apply custom theming should any be provided.
        this.themeService.applyTheme(initializeResponse.theme);

        // populate the stores with data in a cascade
        this.appService.registerReady(initializeResponse.lifecycleReady);

        // populate the cookies
        this.cookiesService.applyConfiguration(initializeResponse.cookies);

        this.loggerService.log('[App] initialized');

        // navigate to the destination this will trigger one of the options above
        this.appService.openLink(initializeResponse.destination);

        // Fake Standard Error Testing
        // throw createDigitaServiceError('AppComponent', 'ngOnInit', 'This is a fake Digita Service Error 💀', 'internal');
        // throw new HttpErrorResponse({ status: 422, statusText: 'This is a fake HTTP Error 💀', url: 'https://www.google.com' }); // Note: Uncomment out the line at the top of this file as well // import { HttpErrorResponse } from '@angular/common/http';
        // throw new Error('This is a generic unhandled error 💀');
      });
  }
}
